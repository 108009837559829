.home {
    max-width: 600px;
    margin: auto;
}

.home-sidebar {
    width: 130px;
    position: absolute;
    top: 90px;
    left: 14px;
}