.video-listing {
    padding: 10px 16px;
    margin: 20px 0;
    border-bottom: 1px solid #fafafa;
}

.video-listing:hover {
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
}

.video-listing h2 {
  font-size: 20px;
  color: #f1356d;
  margin-bottom: 8px;
}

.video-listing a{
  text-decoration: none;
}