.create {
  text-align: center;
}

.create label {
  text-align: left;
  display: block;
}

.create h2 {
  font-size: 20px;
  color: #f1356d;
  margin-bottom: 30px;
}

.create input, .create textarea, .create select {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
}

.create .find {
  background: darkgray;
  border: 0;
  padding: 15px 50px;
  border-radius: 8px;
  width: 170px;
  text-align: center;
  cursor: pointer;
}

.create .paste {
  background: darkgray;
  border: 0;
  padding: 15px 50px;
  border-radius: 8px;
  width: 170px;
  text-align: center;
  cursor: pointer;
  float: left;
}

.create .import {
  background: #0275d8;
  border: 0;
  padding: 15px 50px;
  border-radius: 8px;
  width: 170px;
  text-align: center;
  cursor: pointer;
  float: right;
}

.clear {
  background: #d9534f;
  border: 0;
  padding: 15px 50px;
  border-radius: 8px;
  width: 170px;
  text-align: center;
  cursor: pointer;
  float: right;
  margin-top: 20px;
  margin-left: 50px;
}

.form-buttons {
  padding-top: 20px;
}