.sidenav {
    width: 160px;
    top: 90px;
    left: 14px;
    padding: 5px 0;
  }
  
.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}
  
.sidenav a:hover {
  color: #f1356d;
}