.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
  position: absolute;
  z-index: 0;
}

.navbar h1 {
  color: #f1356d;
}

.navbar .links {
  margin-left: auto;
  padding-bottom: 20px;
}

.navbar h1 a {
  color: #f1356d;
  margin-left: 0px;
  text-decoration: none;
  padding: 6px;
}

.navbar .new-button a {
  color: white;
  background-color: #f1356d;
  border-radius: 8px;
  margin-left: 0px;
  text-decoration: none;
  padding: 6px;
  margin-left: 200px;
  position: absolute;
  bottom: 18px;
}

.login, .logout{
  color: white;
  background-color: #f1356d;
  border-radius: 8px;
  border: none;
  padding: 6px;
  width: 175.52px;
}
